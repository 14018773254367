import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../components/footer/footer";
import Navbar from "../components/nav-bar/navbar";
import SideBar from "../components/sidebar/sidebar";
import "./after-send.css";

import { CSSTransitionGroup } from "react-transition-group";

export default function AfterSend() {
  const [showOne, setshowOne] = useState(true);
  const [showTwo, setshowTwo] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setshowTwo(true);
      setshowOne(false);
    }, 2000);
  }, []);
  return (
    <div>
      <Navbar />
      <SideBar />
      <Footer />
      <Container fluid>
        <Row>
          <Col
            sm={12}
            className="after-send-title d-flex justify-content-center align-items-center"
          >
            {showOne && (
              <CSSTransitionGroup
              transitionName="example"
              transitionAppear={true}
              transitionAppearTimeout={500}
              transitionEnter={false}
              transitionLeave={false}
              >
                <h1 className="as-tille">
                  BE <br></br>HERE{" "}
                </h1>
                <h2 className="as-tille">BE PRESENT</h2>
              </CSSTransitionGroup>
            )}
            {showTwo && (
              <CSSTransitionGroup
              transitionName="example"
              transitionAppear={true}
              transitionAppearTimeout={500}
              transitionEnter={false}
              transitionLeave={false}
              >
                <h1 className="as-tille">
                  THANK <br></br>YOU{" "}
                </h1>
              </CSSTransitionGroup>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
