import { navigate } from "gatsby";
import React from "react";
import "./nav-bar.css";
export default function Navbar(props) {
  const scrollTo = (id) => {
    if (props.isIndex) {
      document.getElementById(id).scrollIntoView();
    } else {
      navigate("/");
    }
  };
  return (
    <nav className="nav-container">
      <img src={require("../../assets/logo.svg")} className="logo" />
      <ul className="nav-bar mr-5">
        <li className="ml-3">
          <a
            onClick={() => {
              scrollTo("what-we-do");
            }}
          >
            Services
          </a>
        </li>
        <li className="ml-3">
          <a
            onClick={() => {
              scrollTo("explore-our-work");
            }}
          >
            Projects
          </a>
        </li>
        <li className="ml-3">
          <a
            onClick={() => {
             scrollTo('contacts')
            }}
          >
            Contact Us
          </a>
        </li>
        <li className="ml-3">
          <a
          >
            Apply With Us
          </a>
        </li>
      </ul>
    </nav>
  );
}
