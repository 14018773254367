import React from "react";
import "./footer.css";
export default function Footer() {
  return (
    <footer className="footer-desktop">
      <div className="terms-and-conditios-wrapper">
        <p>Terms & Conditions</p>
        <p className="ml-4"> Privacy Policy</p>
      </div>

      <div>
        <ul className="social-icons">
          <li className="mr-3">
            <img
              src={require("../../assets/social-icons/telegram.svg")}
              alt="telegram icon"
              onClick={()=>{window.open('https://www.facebook.com/exmersolutions')}}
            />
          </li>
          <li
            className="mr-3"
          >
            <img
              src={require("../../assets/social-icons/facebook.svg")}
              alt="facebook icon"
              onClick={()=>{window.open('https://www.facebook.com/exmersolutions')}}
            />
          </li>
          <li className="mr-3">
            <img
              src={require("../../assets/social-icons/instagram.svg")}
              alt="instagram icon"
              onClick={()=>{window.open('https://www.instagram.com/exmerdev/')}}
            />
          </li>
          <li className="mr-3">
            <img
              src={require("../../assets/social-icons/linkedin.svg")}
              alt="linkedin icon"
              onClick={()=>{window.open('https://www.linkedin.com/company/exmer-dev/about/')}}
            />
          </li>
        </ul>
      </div>
    </footer>
  );
}
