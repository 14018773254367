import React from "react";
import './side-bar.css';
export default function SideBar() {
  return (
    <nav className='sidebar'>
    <img src={require('../../assets/logo.svg')} width='70%' style={{marginTop:'-2rem'}}/>
     <p className='side-tittle'>Providing Software Agency</p>
      <ul>
        <li >
          <img
            src={require("../../assets/social-icons/telegram.svg")}
            alt="telegram icon"
          />
        </li>
        <li >
          <img
            src={require("../../assets/social-icons/facebook.svg")}
            alt="facebook icon"
          />
        </li>
        <li >
          <img
            src={require("../../assets/social-icons/instagram.svg")}
            alt="instagram icon"
          />
        </li>
        <li>
          <img
            src={require("../../assets/social-icons/linkedin.svg")}
            alt="linkedin icon"
          />
        </li>
      </ul>
    </nav>
  );
}
